import http from '../../utils/http'

// 计划用电
export const apiElectricityPlan = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-energy/planned/electricity/plans',
            method: 'get',
            params
        })
    },
    // 初始化
    init(data) {
        return http.request({
            url: '/cemp-energy/planned/electricity/initialize',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-energy/planned/electricity/change',
            method: 'put',
            data
        })
    },
}